import * as OM from '@/Model';

export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}

export class GWattMinEmissionCalculation extends OM.EmissionCalculation {
    grams : OM.KeyValuePairOfStringAndDouble;
    watt : OM.KeyValuePairOfStringAndDouble;
    minutes : OM.KeyValuePairOfStringAndDouble;
    countryEmission : number;

    constructor() 
    {
    	        super();
		            this.grams = new OM.KeyValuePairOfStringAndDouble();
                this.watt = new OM.KeyValuePairOfStringAndDouble();
                this.minutes = new OM.KeyValuePairOfStringAndDouble();
                this.countryEmission = null ;
        }
}

export class SingleGEmissionCalculation extends OM.EmissionCalculation {
    grams : OM.KeyValuePairOfStringAndDouble;

    constructor() 
    {
    	        super();
		            this.grams = new OM.KeyValuePairOfStringAndDouble();
        }
}

export let Roles = {
    Admin: "admin",
    Employee: "employee",
    CompanyManager: "companymanager",
    InternalUser: "internaluser",
}