import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/eco-pilot/:estimationIdentifier',
            name: 'bestCombination',
            component: () => import("./views/bestCombination.vue")
        },
        {
            path: '/best-footwear-combination/:estimationIdentifier',
            name: 'bestFootwearCombination',
            component: () => import("./views/bestFootwearCombination.vue")
        },
        {
            path: '/best-bag-combination/:estimationIdentifier',
            name: 'bestBagCombination',
            component: () => import("./views/bestBagCombination.vue")
        }
    ];
};